.hero-container {
  height: auto;
  display: flex;
  flex-grow: 1;
  flex-flow: wrap;
  .hero-left {
    display: flex;
    justify-content: center;
    img {
      width: 100%;
    }
  }
  .hero-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: 540;
    padding: 15px;
    align-items: center;
    text-align: center;
    span {
      font-weight: 20px;
      color: #1eac0f;
    }
    .percent {
      font-weight: 800;
      font-size: 55px;
      color: #1eac0f;
    }
  }
}

@media screen and (min-width: 600px) {
  .hero-container {
    .hero-left {
      justify-content: right;
      width: 45%;
    }
    .hero-right {
      width: 55%;
      padding-right: 20px;
      align-items: center;
      text-align: right;
    }
  }
}

@media screen and (min-width: 900px) {
  .hero-container {
    height: 500px;
    justify-content: space-around;
    .hero-left {
      justify-content: center;

      height: 100%;
      img {
        width: auto;
      }
    }
    .hero-right {
      font-size: 30px;
      align-items: flex-start;
      height: 100%;
      padding-right: 50px;
      text-align: left;
      span {
        font-weight: 600;
        color: #1eac0f;
      }
      .percent {
        font-weight: 800;
        font-size: 95px;
        color: #1eac0f;
      }
    }
  }
}

.stats-container {
  background-color: #c2272d;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  text-align: center;
  padding: 20px;
  h1 {
    font-size: 25px;
  }
  p {
    font-size: 18px;
  }
  .cards-container {
    display: flex;
    justify-content: space-around;
    .card {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 20px;
      h1 {
        font-size: 30px;
      }
      span {
        font-size: 12px;
      }
    }
  }
}

@media screen and (min-width: 600px) {
  .stats-container {
    h1 {
      font-size: 35px;
    }
    p {
      width: 80%;
    }
    .cards-container {
      .card {
        h1 {
          font-size: 40px;
        }
        span {
          font-size: 20px;
        }
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  .stats-container {
    h1 {
      font-size: 45px;
    }
    p {
      width: 50%;
    } 
    .cards-container {
      .card {
        h1 {
          font-size: 50px;
        }
        span {
          font-size: 22px;
        }
      }
    }
  }
}

.services-container {
  margin-top: 30px;
  .entete {
    width: 100%;
    text-align: center;
    padding: 10px;
    h1{
      font-size: 24px; 
    }
    p {
      font-size: 18px;
      color: #646872e3;
    }
  }
  .services {
    padding: 20px;
    display: flex;
    flex-flow: wrap;
    .card {
        p{
            color: #646872e3;
            font-size: 18px;
        }
        img{
          height: 60px;
          margin-top: 20px;
          margin-right: 10px;
        }
        h3{
          font-size: 20px;
          font-weight: 600;
          a{
            color: black;
            text-decoration: none;
          }
      }
    }
  }
}

@media screen and (min-width: 600px) {
  .services-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    .entete {
      width: 80%;
      h1{
        font-size: 40px;
      }
    }
    .services {
      padding: 20px;
      .card {
        display: flex;
        width: 50%;
        padding: 20px;
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  .services-container {
    .entete {
      width: 45%;
    }
    .services {
        justify-content: center;
        width: 85%; 
      .card {
        width: 30%;
      
      }
    }
  }
}

.footer-container {
  background-color: #c2272d;
  color: white;
  height: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 400;

  .icons-list{
    display: flex;
    margin-top: 10px;
    justify-content: space-around;
  }
}

.form-container {
  width: 100%;
  padding: 0;

  p {
    text-align: center;
    width: 100%;
    font-weight: 400;
  }

  .form {
    display: flex;
    flex-flow: wrap;

    .form-left {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      .fm-input {
        padding: 20px 20px;
        border: 1px solid #ccc;
        border-radius: 4px;
        width: 80%;
        margin-top: 20px;
      }
      p{
        width: 80%;
      }
      select {
        padding: 20px 20px;
        border: 1px solid #ccc;
        border-radius: 4px;
        width: 80%;
        margin-top: 20px;
      }
    }

    .form-right {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      p{
        width: 80%;
      }
      .fm-input {
        padding: 20px 20px;
        border: 1px solid #ccc;
        border-radius: 4px;
        width: 80%;
        margin-top: 20px;
      }
      select {
        padding: 20px 20px;
        border: 1px solid #ccc;
        border-radius: 4px;
        width: 80%;
        margin-top: 20px;
      }
    }
    .form-submit {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 20px;
      button{
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 700;
        font-size: 20px;
        width: 80%;
        margin-top: 20px;
        border-radius: 15px;
        height: 45px;
        background-color: #1eac0f;
        color: white;
        border: none;
      }
    }
  }
}

@media screen and (min-width: 600px) {
  .form-container {
    .form {
      .form-left {
        width: 50%;
      }

      .form-right {
        width: 50%;
      }

      .form-submit {
        button{
          width: 25%;
        }
      }
    }
  }
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  font-size: 24px;
}

*{
  box-sizing: border-box;
}



.testimonial-container{
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 30px;
    .testimonials{
        display: flex;
        justify-content: space-around;
        flex-flow: wrap;
        margin-top: 5px;
        img{
            height: 45px;
            margin-left: 5px;
        }
    }
    h4{
        font-weight: 600;
    }

}


@media screen and (min-width: 600px) {
    .testimonial-container{
        .testimonials{
            display: flex;
            justify-content: space-around;
            flex-flow: wrap;
            margin-top: 10px;
            img{
                height: 80px;
                margin-left: 20px;
            }
        }
        h4{
            font-weight: 600;
        }
    
    }
    
  }
  
  @media screen and (min-width: 1200px) {
    .testimonial-container{
        display: flex;
        align-items: center;
        flex-direction: column;
        .testimonials{
            display: flex;
            justify-content: space-around;
            flex-flow: wrap;
            margin-top: 20px;
            img{
                height: 100px;
                margin-left: 20px;
            }
        }
        h4{
            font-weight: 600;
        }
    
    }
    
  }
  